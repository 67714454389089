<template>
  <!--jimir3x-->
  <div class="container-fluid login-container-fluid auth-first"
       style="background:#EFF2F6"  
  >
    <!--jimir3x-->
    <loading :loading="loading" />

    <div class="login-topbar px-4 py-2">
      <b-row class="new-row">
        <b-col cols="6">
          <img src="/img/bludots.svg " alt />
        </b-col>
        <b-col cols="6" class="text-right">
          <div class="item">
            <a href="#" @click.prevent="cancelStep">
              <span class="icon">
                <v-icon :icon="['fac', 'close']" />
              </span>
            </a>
          </div>
        </b-col>
      </b-row>
    </div>

    <div v-if="!loading" class="row justify-content-around align-items-center">
      <div class="col-md-12 p-0">
        <div class="auth-right-header ">
          <p>{{ $t('auth.general.isNotHaveAccount') }}</p>
          <router-link to="/auth/register" class="btn btn-outline-primary">
            {{ $t('auth.general.join') }}
          </router-link>
        </div>
      </div>
    </div>
    <div
      class="row justify-content-around align-items-center mb-2 form-area-width"
      v-show="!loading"
    >
      <div class="col-12">
        <div class="row justify-content-around align-items-center text-center">
          <!-- <div class="col-12">
            <h3 class="auth-title-semi mt-2">{{ $t('auth.login.desc') }}</h3>
          </div> -->
        </div>

        <div class="row justify-content-around align-items-center mt-4">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(formSubmit)" action="#">
                <div class="form-group mb-3">
                  <validation-provider
                    name="email"
                    :rules="{ required: true, min: 3, email: true }"
                    v-slot="validationContext"
                  >
                    <label for="#signInEmail">
                      {{ $t('auth.general.emailLabel') }}
                    </label>
                    <div
                      class="input-group input-icon bg"
                      :class="{
                        'is-invalid':
                          !getValidationState(validationContext) &&
                          validationContext.errors[0],
                      }"
                    >
                      <b-form-input
                        :placeholder="$t('auth.general.email')"
                        type="email"
                        id="signInEmail"
                        name="loginEmail"
                        v-model.trim="form.email"
                      ></b-form-input>
                      <div class="input-group-append">
                        <span class="input-group-text">
   
                      <svg style="width:24px;height:24px; color:hsl(199, 100%, 50%);" viewBox="0 0 24 24">
                          <path fill="currentColor" d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
                      </svg>
                        </span>
                      </div>
                    </div>
                    <small class="text-danger">{{
                      validationContext.errors[0]
                    }}</small>
                  </validation-provider>
                </div>
                <div class="form-group mb-5">
                  <validation-provider
                    name="password"
                    :rules="{ required: true, min: 8 }"
                    v-slot="validationContext"
                  >
                    <label for="#signInPw">
                      {{ $t('auth.general.passwordLabel') }}
                    </label>
                    <div
                      class="input-group input-icon bg"
                      :class="{
                        'is-invalid':
                          !getValidationState(validationContext) &&
                          validationContext.errors[0],
                      }"
                    >
                      <b-form-input
                        :placeholder="$t('auth.general.password')"
                        type="password"
                        id
                        name="password"
                        v-model.trim="form.pw"
                      ></b-form-input>
                      <div class="input-group-append">
                        <span class="input-group-text">
                        <svg style="width:24px;height:24px; color:hsl(199, 100%, 50%);" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12,17A2,2 0 0,0 14,15C14,13.89 13.1,13 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V10C4,8.89 4.9,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z" />
</svg>
                        </span>
                      </div>
                    </div>
                    <small class="text-danger">{{
                      validationContext.errors[0]
                    }}</small>
                  </validation-provider>
                </div>
                <div class="d-flex justify-content-between">
                  <label  class="custom-checkbox">

                    <span style="margin-left:10px">{{ $t('auth.general.remember') }}</span>
                    <input spellcheck="true" v-model="form.isRemember" type="checkbox" />
                    <span class="checkmark"></span>
                  </label>
                  <a class="text-right" href="/auth/forgot-password">{{
                    $t('auth.general.forgotPw')
                  }}</a>
                </div>
                <div class="form-group mt-4 mb-0">
                  <button type="submit" class="btn btn-primary btn-block">
                    {{ $t('auth.general.signIn') }}
                  </button>
                </div>
              </form>
            </validation-observer>
            <div class="border-title">
              <p class="title">{{ $t('auth.general.or') }}</p>
            </div>
            <div class="form-group my-4">
              <a
                :href="`${API_DOMAIN}/auth/linkedin`"
                class="btn btn-linkedin btn-block"
              >
                {{ $t('auth.general.signLinkedin') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Login from '../../../graphql/auth/login.gql';
import checkStep from '@/graphql/auth/checkStep.gql';
import checkToken from '@/graphql/me/checkToken.gql';
import loading from '../../../components/global/loading/index';
import countInquiries from '@/graphql/me/countInquiries.gql';
import getInquiries from '@/graphql/me/getInquiries.gql';
import getProducts from '@/graphql/me/getProducts.gql';
import { mapMutations } from 'vuex';
import sha256 from 'sha256';
import _ from 'lodash';
export default {
  name: 'Login-page',
  components: {
    loading,
  },
  data() {
    return {
      loading: false,
      form: {
        email: null,
        pw: null,
        isRemember: false,
      },
    };
  },
  methods: {
    async getLinkedQ() {
      if (this.$route.query.q) {
        var linkedinQ = this.$route.query.q;

        var decodeLinkedinQ = atob(linkedinQ);

        var parsedLinkedin = JSON.parse(decodeLinkedinQ);

        try {
          this.loading = true;
          let variable = {
            token: parsedLinkedin.token,
          };

          let data = await this.$apollo.query(checkToken, variable);
          let response = data('**.checkToken');
          console.log(response);
          if (response.member.on_boarding) {
            this.SET_USER_TYPE(response.member.type);
            this.setEmail(response.member.email);
            this.setToken(response.token.token);

            if (response.member.type === 'SELLER') {
              this.setCompleteStep(2);
              let list = await this.getProducts();
              if (list.length > 0) {
                return this.$router.push('/register-steps/step-3/products');
              }
              return this.$router.push('/register-steps/step-3/product-add');
            }
            if (response.member.type === 'BUYER') {
              this.setCompleteStep(2);
              await this.getInquiries();
              let list = this.getUserList;
              if (list.length > 0) {
                return this.$router.push('/register-steps/step-3/products');
              }
              return this.$router.push('/register-steps/step-3/inqury-add');
            }
          }
          if (!response.member.on_boarding) {
            window.localStorage.setItem('user_type', response.member.type);
            await this.SET_USER_TYPE(response.member.type);
            await this.setToken(response.token.token);
            this.$io.refresh();
            return this.$router.push('/app/dashboard');
          }
        } catch (e) {
          if (e.message === 'AUTH.NO_COMPLETE_STEP') {
            return await this.checkStepUser();
          }
          let msg = this.$options.filters.gqlep(e.message);
          console.log(msg);

          this.$refs.observer.setErrors({ email: [msg], password: [msg] });
        } finally {
          this.loading = false;
        }
      }
    },
    ...mapMutations({
      setEmail: 'general/setEmail',
      setCompleteStep: 'general/setCompleteStep',
      setToken: 'general/setToken',
      setUserList: 'inquiries/setUserList',
      SET_USER_TYPE: 'general/SET_USER_TYPE',
    }),
    async checkStepUser() {
      let checkVariable = {
        email: this.form.email,
      };
      let data = await this.$apollo.query(checkStep, checkVariable);
      let response = data('**.checkStep');
      this.setEmail(this.form.email);
      if (!_.get(response, 'company.name', false)) {
        this.setCompleteStep(1);
        return this.$router.push('/register-steps/step-1');
      }

      if (!response.type) {
        this.setCompleteStep(2);
        return this.$router.push('/register-steps/step-2');
      }

      if (response.type) {
        this.setCompleteStep(3);
        if (response.type === 'BUYER') {
          return this.$router.push('/register-steps/step-3/inqury-add');
        }
        if (response.type === 'SELLER') {
          return this.$router.push('/register-steps/step-3/product-add');
        }
      }
    },
    async getInquiries() {
      let params = {
        limit: 1,
        filters: {},
      };
      await this.$store.dispatch('inquiries/fetchInquiriesList', {
        vm: this,
        payload: params,
      });
    },
    async getProducts() {
      let params = {
        limit: 1,
        filters: {},
      };
      let data = await this.$apollo.query(getProducts, params);
      let response = data('**.list');
      this.setUserList(response);
      return response;
    },
    async formSubmit() {
      try {
        this.loading = true;
        let variable = {
          email: this.form.email,
          password: sha256(this.form.pw),
        };
        let data = await this.$apollo.mutate(Login, variable);
        let response = data('**.login');
        if (response.member.on_boarding) {
          this.SET_USER_TYPE(response.member.type);
          this.setEmail(this.form.email);
          this.setToken(response.token.token);

          if (response.member.type === 'SELLER') {
            this.setCompleteStep(3);
            let list = await this.getProducts();
            if (list.list.length > 0) {
              return this.$router.push('/register-steps/step-3/products');
            }
            return this.$router.push('/register-steps/step-3/product-add');
          }
          if (response.member.type === 'BUYER') {
            this.setCompleteStep(3);
            await this.getInquiries();
            let list = this.getUserList;
            if (list.list.length > 0) {
              return this.$router.push('/register-steps/step-3/products');
            }
            return this.$router.push('/register-steps/step-3/inqury-add');
          }
        }
        if (!response.member.on_boarding) {
          window.localStorage.setItem('user_type', response.member.type);
          await this.SET_USER_TYPE(response.member.type);
          await this.setToken(response.token.token);
          this.$io.refresh();
          //   yönlendir
          return this.$router.push('/landing');
        }
      } catch (e) {
        if (e.message === 'ERROR.NO_COMPLETE_STEP') {
          return await this.checkStepUser();
        }
        let msg = this.$options.filters.gqlep(e.message);
        console.log(msg);

        this.$refs.observer.setErrors({ email: [msg], password: [msg] });
      } finally {
        this.loading = false;
      }
    },

    getValidationState({ dirty, validated, valid = null }) {
      if (dirty || validated) {
        return valid;
      }
      return null;
    },
  },
  created() {
    this.getLinkedQ();
  },
};
</script>
